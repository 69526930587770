import React, { useState, useEffect } from "react";
import "./receipt.css";
import { useRef } from "react";

import axios from "axios";
import Table from "./Table_render";
function Receipt(props) {
  

  const p = useRef(-1);
  function check(formvalue) {
    if (formvalue === "DROPDOWN") {
      p.current = 1;
    } else {
      p.current = 0;
    }
  }
  
  const [formData, setForm] = useState(null);
  const x = useRef(false);
  
  useEffect(() => {
    async function fetchForm() {
      x.current = false;
      try {
        const response = await axios.get(`https://mascot-footwear.appspot.com/form/${props.formid}`
        );
        setForm(response.data);
        if (response.data != null) {
          x.current = true;
        } else {
          x.current = false;
        }
      } catch (error) {}
    }
    fetchForm();
  }, [props]);
 

  return (
    <>
      {" "}
      {x.current && formData && formData.filledByInfos && (
        <div className="main">
          {formData.filledByInfos.map((field, index) =>
            check(field.display.inputType)
          )}
          
          <div className="container">
        {   <Table formdata={formData} p={p.current} />}
            
          </div>
        </div>
      )}
    </>
  );
}
export default Receipt;
