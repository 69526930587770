import React from 'react'
import { Link } from 'react-router-dom'
import Receipt from './components/receipt';
import "./components/receipt.css";

// "id": "dc70a0e1-9cf3-4b14-8e3f-781cad7aaae8",
  // "id": "41210f34-f037-4641-bb94-87470d94e292",
  import { useState } from 'react';
  import { BrowserRouter as Router } from 'react-router-dom'
  function App() {
    const [formID, setFormID] = useState("");
    const [show, setShow] = useState(false);
  
    const handleSubmit = () => {
      setShow(true);
    };
  
    return (
      <Router>
      <div>
        <div>
          <label> <h1>Enter Form id:</h1> </label>
          <input type="text" id='hhh' onChange={(e) => { setFormID(e.target.value); setShow(false) }} />
          <Link to={`/debug/form?id=${formID}`}>
            <button className="submit-button" onClick={handleSubmit}>
              <span className="submit-button__text">Submit</span>
            </button>
          </Link>
        </div>
        {show && <Receipt formid={formID} />}
      </div>
      </Router>
    );
  }

export default App;
