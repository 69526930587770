import React, { useState, useEffect} from "react";
import "./receipt.css";
import Header from "./header";
import { MdDelete} from "react-icons/md";
import Receipt from "./receipt";
import Heading from "./head_description";

function Table(props) {
  const[Main_total,setMain]=useState(null);
  const [rows, setRows] = useState([]);
  const [Submit,setsubmit]=useState("Submit")
    const [total_row, setTotal] = useState({

  
    col1: "",
    col2: "",
    col3: "",
    col4: "",
    col5: "",
    col6: "",
    col7: "",
    col8: "",
    col9: "",
  });



  useEffect(() => {
    if (props.formdata !== null && props.formdata.filledByInfos) {
      let array = [];
      for (let i = 0; i < 4; i++) {
        let obj = {};
        let x = 0;
        for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {
          obj[`col${j}`] = props.formdata.filledByInfos[j - 1].defaultValue;
          x += parseInt(props.formdata.filledByInfos[j - 1].defaultValue) || 0;
        }
        obj[`col${props.formdata.filledByInfos.length + 1}`] = x;
        array.push({
          id: i + 1,
          ...obj,
        });
      }
      setRows(array);
    }
  }, [props.formdata]);

  useEffect(() => {
    if (props.formdata !== null && props.formdata.filledByInfos) {

      let a = 1; 
      let obj={}
      
      for (let i = 1; i <= props.formdata.filledByInfos.length; i++) {
        if(rows.length===0){
           for(let i=1;i<=7;i++){
            obj[`col${i}`] = 0;
           }
          
        }else{
        let flag=0;
        let z=0;
      for(let p=0;p<rows.length;p++){
       
           if(props.formdata.filledByInfos[i - 1].display.inputType==="NUMBER"){
          z += parseInt(rows[p][`col${i}`]) || 0;
          flag=1;
        }
     }
    
    
  if(flag===1){
         obj[`col${a++}`] = z;}
       

   
     
      

      }}
     
      
     setTotal(obj);
    }
    
  }, [props.formdata,rows]);

  useEffect(()=>{
     let x=0;
     for(let p=0;p<rows.length;p++){
      x+= parseInt(rows[p][`col${props.formdata.filledByInfos.length+1}`])||0;
     }
    
    setMain(x);
   

  },[rows,props.formdata,total_row])



const[value,setvalue]=useState("");

  function addRow() {
    let newRow = { id: Date.now() };
    let x = 0;
    if (props.formdata && props.formdata.filledByInfos.length) {
      for (let i = 1; i <= props.formdata.filledByInfos.length; i++) {
        newRow[`col${i}`] = props.formdata.filledByInfos[i - 1].defaultValue;
        x += parseInt(props.formdata.filledByInfos[i - 1].defaultValue) || 0;
      }
    }
    setRows([...rows, newRow]);
    newRow[`col${props.formdata.filledByInfos.length + 1}`] = x;
   
  }


  function deleteRow(id) {
    
    setRows(rows.filter((row) => row.id !== id));
  }
  

   

  const handleSubmit = async () => {
    let idArray = []
    setvalue("");
    setsubmit('Submitting.....')
    for(let i=0;i<rows.length;i++){
        let main_obj={
          formRef: {
            id: "41210f34-f037-4641-bb94-87470d94e292",
            name: "Order Details"
          },
          organisationRef: {
            id: "mascot-footwear",
            name: "Mascot Footwear"
          },
          answers: [],
          formTypeEnum: "ORDER_DETAILS",
          source: "WEB_FORM",
          extraData: {},
          filledByUserProfile: null,
          feedbackBackRef: null,
          submissionDone: true
        }
        
        for(let j=1; j<= props.formdata.filledByInfos.length; j++){
            let ref_obj={
                fieldNo: props.formdata.filledByInfos[j-1].fieldNo,
                value: rows[i][`col${j}`],
                type: props.formdata.filledByInfos[j-1].display.inputType,
                label: props.formdata.filledByInfos[j-1].display.label,
                casePreserve: null,
                parentWidgetNo: 0,
                altValue: "",
                configId: ""
            }
            main_obj.answers.push(ref_obj);
        }
       
      
      
          try {
            const response = await fetch('https://mascot-footwear.appspot.com/formFilled', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(main_obj)
            });
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            const jsonResponse = await response.json();
            console.log(`order_details_row_${i+1}_submitted`);
            console.log(jsonResponse)
            idArray.push(jsonResponse.id);
          } catch (error) {
            setsubmit("Error!")
            console.error(error);
          }
        };
      
      
       
      let idValue = idArray.join(',')
      setvalue(idValue)
      if(Submit!=="Error!"){
      setsubmit("Submitted ✔️");
      setTimeout(() => {
        setsubmit("Submit");
        for(let i=0;i<rows.length;i++){
          rows[i][`col${props.formdata.filledByInfos.length + 1}`]=0;
          for(let j=1; j<= props.formdata.filledByInfos.length; j++){
            
               
                 rows[i][`col${j}`]=props.formdata.filledByInfos[j-1].defaultValue;
                 rows[i][`col${props.formdata.filledByInfos.length + 1}`]+=parseInt(props.formdata.filledByInfos[j-1].defaultValue)||0;
                
             
            
            
        }
  
  
  
        }
        let x=0;
        for(let p=0;p<rows.length;p++){
         x+= parseInt(rows[p][`col${props.formdata.filledByInfos.length+1}`])||0;
        }
       
       setMain(x);
       let a = 1; 
       let ref_obj2={}
       
       for (let i = 1; i <= props.formdata.filledByInfos.length; i++) {
         if(rows.length===0){
            for(let i=1;i<=7;i++){
             ref_obj2[`col${i}`] = 0;
            }
           
         }else{
         let flag=0;
         let z=0;
       for(let p=0;p<rows.length;p++){
        
            if(props.formdata.filledByInfos[i - 1].display.inputType==="NUMBER"){
           z += parseInt(rows[p][`col${i}`]) || 0;
           flag=1;
         }
      }
     
     
   if(flag===1){
          ref_obj2[`col${a++}`] = z;}
        
 
    
      
       
 
       }}
      
       
      setTotal(ref_obj2);
     
       

       
      }, 1500)
     
      
      
      ;
      
    
    
    
    }
      
      
      
      
      
      
      
    
    }


  

  function updateTotal(row, columnIndex, newValue,{inputType}) {
    let regex = "";
    if(inputType==="text"){
      regex=/^[a-zA-Z ]+$/;
    }else{
      regex=/^\d+$/;
    }
    
    let value="";
    
    if (regex.test(newValue)){value=newValue;}else{
      value=newValue.slice(0,-1);
    }
   
    const updatedRows = rows.map((r) =>
      r.id === row.id
        ? {
            ...r,
           
            [`col${columnIndex}`]: value,
          }
        : r
    );
    setRows(updatedRows);
    let total = 0;
    for (let i = 1; i <= props.formdata.filledByInfos.length; i++) {
      if (props.formdata.filledByInfos[i - 1].display.inputType === "NUMBER") {
        total +=
          parseInt(updatedRows.find((r) => r.id === row.id)[`col${i}`]) || 0;
      }
    }
    setRows((prevRows) =>      
    
    prevRows.map((r) => {
        if (r.id === row.id) {
          r[`col${props.formdata.filledByInfos.length + 1}`] = total;
        }
        return r;
      })
    );
    
  }
 
  return (
    <>
    {   <Heading form={props.formdata} value={value} p={props.p}/>}
      {props.formdata &&
        props.formdata.filledByInfos &&
        !props.formdata.pages && (
          
          <div className="container">
            <h1>{props.formdata.formName}</h1>
            <table
              id="data"
              className="data-table data-table-horizontal data-table-highlight"
            >
              <tbody>
                <Header data={props.formdata.filledByInfos} />
                {rows.map((row) => (
                  <tr key={row.id}>
                    {Array.from(
                      { length: props.formdata.filledByInfos.length + 1 },
                      (_, i) => {
                        const columnIndex = i + 1;
                        let inputType = "";
                        
                        if (i < props.formdata.filledByInfos.length) {
                          if (
                            props.formdata.filledByInfos[i].display
                              .inputType === "NUMBER"
                          ) {
                            if(props.formdata.filledByInfos[i].constraints)
                             
                            inputType = "number";
                          } else if (
                            props.formdata.filledByInfos[i].display
                              .inputType === "TEXT"
                          ) {
                            inputType = "text";
                          }
                        } else {
                          inputType = "number";
                        }
                        return (
                          <td key={`col${columnIndex}`}>
                            <input
                              type={inputType}
                              value={row[`col${columnIndex}`]}
                              onChange={(e) =>{
                              
                                updateTotal(row, columnIndex, e.target.value,{inputType});
                              

                              }
                              }
                              
                            />
                          </td>
                        );
                      }
                    )}
                    <td>
                      {row.id !== 0 ? (
                        <MdDelete
                          className="button"
                          onClick={() => deleteRow(row.id)}
                        />
                      ) : (
                        <MdDelete className="button" value="null" />
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  
                  <td className="hey">
                  
                  </td>
                  <td className="hey">
                  
                  </td>
                 
                  <td className="hey"></td>
                  <td className="respective_totals">
                    {" "}
                    <h2>Total:</h2>
                  </td>
                  <td className="hey">
             
                    <input type="number" value={total_row.col1} />
                  </td>
                  <td className="hey">
               
                    <input type="number" value={total_row.col2} />
                  </td>
                  <td className="hey">
             
                    <input type="number" value={total_row.col3} />
                  </td>
                  <td className="hey">
                 
                    <input type="number" value={total_row.col4} />
                  </td>
                  <td className="hey">
                 
                    <input type="number" value={total_row.col5} />
                  </td>
                  <td className="hey">
                  
                    <input type="number" value={total_row.col6} />
                  </td>
                  <td className="hey">
                 
                    <input type="number" value={total_row.col7} />
                  </td>
                  
                  <td className="hey" >
                 
                    <input type="number" value={Main_total} />
                  </td>
                  
                    
                    
                    <button className="top-buffer" onClick={addRow}>
                    {" "}+
                    </button>
                  
                </tr>
              </tbody>
            </table>
            <button className="submit-button" onClick={handleSubmit}>
            <span className="submit-button__text">{Submit}</span>
            
        </button>
          </div>
         
        )}
      {props.formdata &&
        props.formdata.pages &&
      
        props.formdata.pages.map((field) =>
          field.inputWidgets.map((help) =>
            help.questions.map(
              (root) =>
                root.display.inputType === "OPEN_ANOTHER_FORM" &&(
                  <Receipt formid={root.openAnotherForm.formId} />
                )
            )
          )
        )}
    </>
  );
}

export default Table;
