import React from "react";
import { useEffect, useState } from "react";

import Dropper from "./dropper";
let obj={
    
  formRef: {
    id: "dc70a0e1-9cf3-4b14-8e3f-781cad7aaae8",
    name: "New Order"
  },
  organisationRef: {
    id: "mascot-footwear",
    name: "Mascot Footwear"
  },
  answers: [
  ],
  formTypeEnum: "ORDER",
  source: "WEB_FORM",
  extraData: {},
  submissionDone: true

}


function Head_description(props) {

  
  

  const [formData, setForm] = useState(null);
  useEffect(()=>{
    
    if(props.p===1){
      setForm(props.form);
      console.log(formData);
      if(formData!=null){
  
        for(let j=0;j<formData.filledByInfos.length;j++){
         
          let refobj={
            fieldNo: "",
            value: "",
            type: "",
            label: "",
            casePreserve: null,
            parentWidgetNo: 0,
            altValue: "",
            configId: ""
      
          }
          refobj.fieldNo=formData.filledByInfos[j].fieldNo;
          obj.answers.push(refobj);
         }
         
      
        formData &&
              formData.pages &&
            
            formData.pages.map((field) =>
                field.inputWidgets.map((help) =>
                  help.questions.map(
                    (root) =>
                      root.display.inputType === "OPEN_ANOTHER_FORM" &&(
                      
                       obj.answers.push( {
                          fieldNo: root.fieldNo,
                          value:"",
                          type: root.display.inputType,
                          label: root.display.label,
                          casePreserve: null,
                          parentWidgetNo: 0,
                          altValue: "",
                          configId: null
                        })
                      )
                  )
                )
              )
      
                      }
    }
    

  },[props.p,props.form,formData])
  
   
 


  const handleSubmit = async (reck) => {

    for(let i=0;i<obj.answers.length;i++){
      if(obj.answers[i].type=== "OPEN_ANOTHER_FORM"){
        obj.answers[i].value=reck;
      }
    }
    
    if(obj.answers.length>0){

    fetch('https://mascot-footwear.appspot.com/formFilled', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  }).then(response => {
    var element = document.getElementsByClassName("dropdown");

// Set the value of the element
for(let k=0;k<element.length;k++){
  
  element[k].value = "Not_selected";
}

      if (!response.ok) {
          throw new Error(response.statusText);
      }
      return response.json();
  }).then(jsonResponse => {
    for(let i=0;i<obj.answers.length;i++){
      
        obj.answers[i].value='Not_selected';
        
      }
    

    console.log("new_order_submitted")
  

    
    console.log(jsonResponse)
   

    
  }).catch(error => {
      console.error(error);
  });
  }}

  



useEffect(() => {
 if(props.value!==""){
  handleSubmit(props.value);

  
   
 }}

// eslint-disable-next-line react-hooks/exhaustive-deps
, [props.value]);

 

 
// useEffect(() => {
//     async function fetchForm() {
//       x.current = false;
//       try {
//         const response = await axios.get(
//           "https://mascot-footwear.appspot.com/form/dc70a0e1-9cf3-4b14-8e3f-781cad7aaae8"
//         );
//         setForm(response.data);
//         if (response.data) {

//           for(let j=0;j<formData.filledByInfos.length;j++){
       
//             let refobj={
//               fieldNo: "",
//               value: "",
//               type: "",
//               label: "",
//               casePreserve: null,
//               parentWidgetNo: 0,
//               altValue: "",
//               configId: ""
      
//             }
//             refobj.fieldNo=formData.filledByInfos[j].fieldNo;
//             obj.answers.push(refobj);
//            }
//           x.current = true;
//         } else {
//           x.current = false;
//         }
//       } catch (error) {}
//     }
//     fetchForm();
   
//   });
  
    
      
     
   
  

  function handlechange(e,field){
    
    for(let i=0;i<obj.answers.length;i++){
      if(field.fieldNo===obj.answers[i].fieldNo){
        obj.answers[i].value=e.target.value;
        obj.answers[i].type= field.display.inputType;
        obj.answers[i].label=field.display.label;
        obj.answers[i].casePreserve= null;
        obj.answers[i].parentWidgetNo= 0;
        obj.answers[i].altValue= "";
        obj.answers[i].configId= field.configId;
      }
    }
  }
  function update(field){
    for(let i=0;i<obj.answers.length;i++){
      if(field.fieldNo===obj.answers[i].fieldNo){
        
        obj.answers[i].type= field.display.inputType;
        obj.answers[i].label=field.display.label;
        obj.answers[i].casePreserve= null;
        obj.answers[i].parentWidgetNo= 0;
        obj.answers[i].altValue= "";
        obj.answers[i].configId= field.configId;
      }
    }

 return true }
  
  return (
    <>
      { formData && formData.filledByInfos && (
        <div className="container">
          <h1>{props.form.formName}</h1>

          {/* <div className="row">
            <div className="col-sm-12 col-md-6 ">
              <div id="inputtext">
                <label htmlFor="inputtext">Serial No.:</label>
                <input type="text" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 party" id="party">
              <div id="party">
                {" "}
                <label htmlFor="party">party:</label>
                <input type="text" />
              </div>
            </div>
          </div> */}
           

          <div className="row">
            {formData.filledByInfos.map((field, index) => (
              update(field)&&
              <div key={index} className="col-sm-12 col-md-6">
                <label>{field.display.label}</label>
                <select className="dropdown" htmlFor={field.display.label}  onChange={(e)=> handlechange(e,field)  } 
                >
                <option value="Not_selected"  selected>
                              Select your {field.display.label}{" "}
                            </option>
                  <Dropper configid={field.configId} />
                </select>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Head_description;
