import React from "react";
function Header(props) {
  return (
    <tr className="head" key={0}>
      {props.data.map((field) => (
        <td id={field.fieldNo} className="main_heading">
          {field.display.placeHolder}
        </td>
      ))}
      <td id ="total_col"className="main_heading">Total</td>

    </tr>
  );
}

export default Header;
